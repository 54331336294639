import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import favicon from '../favicon.png'
import '../global.css'

import Nav from '../navigation.js'
import Footer from '../footer.js'
import Projects from '../projects.js'

import projectsBg from '../projectsBg.jpg'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0;
  }
  .mail {
    text-align: center;
    padding: 40px 0 120px;
    .address {
      display: inline-block;
      width: 200px;
      opacity: 0.5;
    }
  }
`

const Hero = styled.header`
  text-align: center;
  position: relative;
  height: 50%;
  background-image: url(${projectsBg});
  background-size: cover;
  background-position: center;
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2.4em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
      width: 90%;
      display: inline-block;
      margin-top: 10px;
      font-size: 1.2em;
      @media (max-width: 800px) {
        font-size: 1.1em;
        width: 80%;
      }
    }
  }
`

export default () => (
  <Container>
    <Helmet>
      <title>Splendo</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Splendo" />
      <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />

    </Helmet>

    <Nav />
    <Hero>
      <div className="wrapper">
        <h1>Our Products</h1>
        <span>A collection of our latest products</span>
      </div>
    </Hero>
    <div id="products-wrapper">
    <Projects />
    </div>
    <Footer />
  </Container>
)
